<template>
     <section id="Projects" class="s-portfolio target-section">
            <div class="row s-portfolio__header">
                <div class="column medium-8 tab-full">
                    <div class="section-intro" data-num="03">
                        <h3 class="subhead">Projects</h3>
                        <h1 class="display-1">
                            A selection of recent projects I have worked on.
                        </h1>
                    </div>
                </div>
            </div>
            <!-- s-porfolio__header -->

            <div class="row s-porfolio__list block-large-1-2 block-tab-full collapse">
                <div class="portfolio-tabs">
                    <div class="folio-item">
                        <div class="folio-item__thumb">
                            <a class="folio-item__thumb-link"  href="JavaScript:void(0);" title="Image Classifier" data-size="1050x700">
                                <img src="../assets/images/ml.png" alt="" />
                            </a>
                        </div>
                        <div class="folio-item__info">
                            <div class="folio-item__cat">Machine Learning (Python,Deep Learning)</div>
                            <h4 class="folio-item__title">Image Classifier </h4>
                        </div>
                        <a href="https://github.com/johnwanjema/Image-classifier-project" target="blank" title="Image Classifier" class="folio-item__project-link">Project Link</a>
                    </div>
                </div>
                <div class="portfolio-tabs">
                    <div class="folio-item">
                        <div class="folio-item__thumb">
                            <a class="folio-item__thumb-link"  href="JavaScript:void(0);" title="Melita Analytics" data-size="1050x700">
                                <img src="../assets/images/vipas.png"  alt="" />
                            </a>
                        </div>
                        <div class="folio-item__info">
                            <div class="folio-item__cat" style="color:white">Frontend Developer(Vue JS, Cloud Computing)</div>
                            <h4 class="folio-item__title">vipas HCA Training Institute</h4>
                        </div>
                        <a href="https://vipashcatraininginstitute.com/" target="blank" title="Melita Analytics" class="folio-item__project-link">Project Link</a>
                    </div>
                </div>
                <!-- end column -->
               
                <!-- end column -->
                <div class="portfolio-tabs">
                    <div class="folio-item">
                        <div class="folio-item__thumb">
                            <a class="folio-item__thumb-link"  href="JavaScript:void(0);" title="Kodipay" data-size="1050x700">
                                <img src="../assets/images/afh.png"  alt="" />
                            </a>
                        </div>
                        <div class="folio-item__info">
                            <div class="folio-item__cat">Full Stack developer(Python,Vue JS, MYSQL, Version control)</div>
                            <h4 class="folio-item__title">AFH Manager</h4>
                        </div>
                        <a target="blank" href="https://www.afhmanager.com/"  title="AFH Manager" class="folio-item__project-link">Project Link</a>
                    </div>
                </div>
                <!-- end column -->
                <div class="portfolio-tabs">
                    <div class="folio-item">
                        <div class="folio-item__thumb">
                            <a class="folio-item__thumb-link" href="JavaScript:void(0);" title="Airbnb Clone" data-size="1050x700">
                                <img src="../assets/images/airbnb.png"  alt="" />
                            </a>
                        </div>
                        <div class="folio-item__info">
                            <div class="folio-item__cat">Frontend developer (React JS)</div>
                            <h4 class="folio-item__title">Airbnb Clone</h4>
                        </div>
                        <a href="https://airbnb-challenge-ivory.vercel.app/" target="blank" title="Airbnb Clone" class="folio-item__project-link">Project Link</a>
                    </div>
                </div>
                <!-- end column -->
            </div>
            <!-- folio-list -->
        </section>
</template>