<template>
    <header class="s-header">
        <div class="header-logo">
            <a href="#hero">
                    <img src="../assets/images/JohnWanjemaLogo.png" alt="Homepage" />
                </a>
        </div>
    
        <div class="header-content">
            <nav class="row header-nav-wrap">
                <ul class="header-nav">
                    <li><a @click="unToggleMeunu" class="smoothscroll" href="#hero" title="Intro">Home</a></li>
                    <li><a @click="unToggleMeunu" class="smoothscroll" href="#about" title="About">About</a></li>
                    <li><a @click="unToggleMeunu" class="smoothscroll" href="#services" title="Services">Services</a></li>
                    <li><a @click="unToggleMeunu" class="smoothscroll" href="#Projects" title="Projects">Projects</a></li>
                    <li><a @click="unToggleMeunu" href="#contact" title="Contact us">Contact</a></li>
                </ul>
            </nav>
            <!-- end header-nav-wrap -->
            <a  href="/Wangui John Resume.pdf"   class="btn btn--stroke btn--small" download>Download CV</a>
        </div>
        <!-- end header-content -->
    
        <a @click="toggleMenu" class="header-menu-toggle" href="#0"><span>Menu</span></a>
    </header>
</template>

<script>
import $ from 'jquery';
export default {
    methods: {
        toggleMenu() {
            $(document).ready(function() {
                const $siteBody = $("body");
                const $toggleButton = $('.header-menu-toggle');

                $toggleButton.toggleClass('is-clicked');
                $siteBody.toggleClass('menu-is-open');
            });
        },
        unToggleMeunu(){
            const $siteBody = $("body");
            const $toggleButton = $('.header-menu-toggle');
            if($(window).width() <= 768){
                $siteBody.removeClass("menu-is-open");
                $toggleButton.removeClass("is-clicked");
            }
        }
    },
}
</script>