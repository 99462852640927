<style scoped>
.activeTab {
    display: block;
}
</style>

<template>
    <section id="services" class="s-services target-section h-dark-bg">
        <div class="row s-services__content">
            <div class="vert-line"></div>
             <div class="column large-12 ">
                <div class="section-intro" data-num="02">
                    <h3 class="subhead">Expertise</h3>
                    <h2 class="display-1" style="font-size:2.9rem">
                        Design, develop, and maintain both client-side and server-side software applications with databases and servers. 
                    </h2>
                </div>
                <p class="lead">
                    <!-- who can tackle projects that involve databases, APIs, build user-facing websites, or even work with clients during the planning phase of projects. -->
                    <!-- Develop Full stack applications with interactive UI/UX experiences with upto date Back end sytems and API's. Create databases and servers and version controlling systems. -->
                    <!-- Develop full stack applications with interactive UI/UX experiences.Design Databases and APIS for -->
                </p>
            </div>
            <div class="column large-6 s-services__leftcol">
                <!-- <div class="profile-pic"> -->
                        <img src="https://github-readme-stats.vercel.app/api/top-langs/?username=johnwanjema&theme=tokyonight" alt="" />
                        <img src=" https://github-readme-stats.vercel.app/api?username=johnwanjema&show_icons=true&theme=tokyonight&line_height=27" alt="" />
                <!-- </div> -->
            </div>
            <!-- end  s-services--leftcol -->
    
            <div class="column large-6">
                <ul class="services-list">
                    <li class="services-list__item is-active">
                        <div class="services-list__item-header">
                            <h5>Design interactive and responsive web design</h5>
                        </div>
                        <!-- <div class="services-list__item-body" :class="{ activeTab: stepOne }">
                            <p>
                             Make sure that every page and  
                              step a user  experiences in their interaction with the finished product conforms to the overall vision created by UX designers.
                            </p>
                        </div> -->
                    </li>
                    <!-- services-list__item -->
                    <li class="services-list__item">
                        <div class="services-list__item-header">
                            <h5 >Creating servers and databases for functionality </h5>
                        </div>
                        <!-- <div class="services-list__item-body"  :class="{ activeTab: stepTwo }">
                            <p>
                                Improve  quality and quantity of website traffic to a website from search engines.
                            </p>
                        </div> -->
                    </li>
                    <!-- services-list__item -->
                    <li class="services-list__item">
                        <div class="services-list__item-header">
                            <h5>Develop and design RESTful services and APIs</h5>
                        </div>
                        <!-- <div class="services-list__item-body"  :class="{ activeTab: stepThree }">
                            <p>
                                create dynamic changes to the appearance of a website, depending on the screen size and orientation of the device being used to view it.
                            </p>
                        </div> -->
                    </li>
                    <!-- services-list__item -->
                    <li class="services-list__item">
                        <div class="services-list__item-header">
                            <h5 >Unit testing and Vesion Control</h5>
                        </div>
                        <!-- <div class="services-list__item-body"  :class="{ activeTab: stepFour }">
                            <p>
                                Test individual units of software
                            </p>
                        </div> -->
                    </li>
                </ul>
                <!-- end services-list -->
            </div>
        </div>
        <!-- s-services__content -->
    </section>
</template>

<script>
export default {
    data() {
        return {
            isActive: true,
            stepOne:false,  
            stepTwo:false,
            stepThree:false,
            stepFour:false,
            stepFive:false,
        }
    },
    methods: {
        // showFiTab() {
        //    this.isActive = !this.isActive
        // }
    }
}
</script>