<template>
    <footer id="contact" class="s-footer h-dark-bg">
        <div class="right-vert-line"></div>
        <div class="row s-footer__main">
            <div class="column large-6">
                <div class="section-intro">
                    <h3 class="subhead">Get In Touch</h3>
                    <h2 class="display-1" style="font-size:3.8rem; padding-bottom:10px">
                        Have an idea or a concept that really excite you? Let's turn that idea into reality. Shoot me a message.
                    </h2>
                </div>
    
                <div class="footer-email-us">
                    <a href="mailto:#jonwanjema@gmail.com" class="btn btn--primary h-full-width">Let's Talk</a>
                </div>
            </div>
    
            <div class="column large-5">
                <div class="footer-contacts">
                    <div class="footer-contact-block">
                        <h5 class="footer-contact-block__header">
                            Email : <a href="mailto:#jonwanjema@gmail.com">jonwanjema@gmail.com</a>
                        </h5>
                    </div>
                    <!-- end footer-contact-block -->
                    <div class="footer-contact-block">
                        <h5 class="footer-contact-block__header">
                            Phone : <a href="tel:+1975432345">+253-921-2357</a>
                        </h5>
                    </div>
                    <!-- end footer-contact-block -->
                    <br />
                    <div class="footer-contact-block">
                        <h5 class="footer-contact-block__header">
                            Social
                        </h5>
                        <ul class="footer-contact-block__list">
                            <li><a target="blank" href="https://github.com/johnwanjema" title="John Wanjema github repository">Github</a></li>
                            <li><a target="blank" href="https://www.linkedin.com/in/john-wanjema-b95930192/" title="John Wanjema linkedin profile">Linkedin</a></li>
                            <li><a target="blank" href="https://stackoverflow.com/users/11614043/john-wanjema" title="John Wanjema stackoverflow profile">StackoverFlow</a></li>
                            <li><a target="blank" href="https://twitter.com/wanjema_" title="John Wanjema twitter profile">Twitter</a></li>
                            <li><a target="blank" href="https://dev.to/wanjema" title="John Wanjema devto profile">Devto</a></li>
                        </ul>
                    </div>
                    <!-- end footer-contact-block -->
                </div>
            </div>
        </div>
        <!-- end s-footer__main -->
    
        <div class="row s-footer__bottom">
            <div class="column large-full ss-copyright">
                <span>© Copyright Ethos 2019</span> 
                <span>Design by <a href="https://www.styleshout.com/">StyleShout</a></span>
            </div> <!-- end ss-copyright -->

            <div class="ss-go-top link-is-visible">
                <a class="smoothscroll" title="Back to Top" href="#top">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0l8 9h-6v15h-4v-15h-6z"></path></svg>
                </a>
            </div> <!-- end ss-go-top -->
        </div>
        <!-- end s-footer__bottom -->
    </footer>
</template>

<script>
import $ from 'jquery'
export default {
    data() {
        return {
            isActive: false,
            year :new Date().getFullYear()  
        }
    },
    created() {

        $(document).ready(function() { //<<====== wont work without this
          const $goTopButton = $(".ss-go-top")
            $(window).scroll(function() {
                if ($(window).scrollTop() >= 820) {
                    if (!$goTopButton.hasClass('link-is-visible')) $goTopButton.addClass('link-is-visible')
                } else {
                    $goTopButton.removeClass('link-is-visible')
                }
                // console.log(this.isActive);
            });
        });
    }
}
</script>