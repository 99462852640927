<template>
    <section id="hero" class="s-hero target-section" data-parallax="scroll" data-image-src="../assets/images/hero-bg-3000.jpg" data-natural-width="3000" data-natural-height="2000" data-position-y="center">
        <div class="row hero-content">
            <div class="column large-full">
                <h1>
                    Hello, I'm John Wanjema, <br /> a Fullstack developer <br /> based in Seattle Wasington.
                </h1>
    
                <ul class="hero-social">
                    <li>
                        <a target="blank" href="https://github.com/johnwanjema" title="John Wanjema github repository">Github/</a>
                    </li>
                     <li>
                        <a target="blank" href="https://www.linkedin.com/in/john-wanjema-b95930192/" title="John Wanjema linkedin profile">Linkedin/</a>
                    </li>
                    <li>
                        <a target="blank" href="https://stackoverflow.com/users/11614043/john-wanjema" title="John Wanjema stackoverflow profile">Stackoverflow/</a>
                    </li>
                    <li>
                        <a target="blank" href="https://twitter.com/wanjema_" title="John Wanjema twitter profile">Twitter/</a>
                    </li>
                    <li>
                        <a target="blank" href="https://dev.to/wanjema" title="John Wanjema devto profile">Devto</a>
                    </li>
                </ul>
                <!-- end hero-social -->
            </div>
        </div>
        <!-- end hero-content -->
    
        <div class="hero-scroll">
            <a href="#about" class="scroll-link smoothscroll">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 24l-8-9h6v-15h4v15h6z" /></svg>
            </a>
        </div>
        <!-- end hero-scroll -->
    </section>
</template>