<template>
    <div>
        <!-- preloader
        ================================================== -->
       <preloaderComponent></preloaderComponent>

        <!-- header
        ================================================== -->
        <headerComponent></headerComponent>

        <!-- hero
        ================================================== -->
       <heroComponent></heroComponent>
        <!-- end s-hero -->

        <!-- about
        ================================================== -->
         <aboutComponent></aboutComponent>
        <!-- end s-about -->

        <!-- services
        ================================================== -->
       <servicesComponent></servicesComponent>
        <!-- end s-services -->

        <!-- portfolio
        ================================================== -->
       <portfolioComponent></portfolioComponent>
        <!-- end portfolio -->

        <!-- testimonials
        ================================================== -->
        <!-- end s-testimonials -->
        <!-- <testimonialComponent></testimonialComponent> -->
        <!-- footer
        ================================================== -->
       <footerComponent></footerComponent>
       
        <!-- end s-footer -->

        <!-- photoswipe background
        ================================================== -->
       <photoSwipComponent></photoSwipComponent>
       
        <!-- end photoSwipe background -->
    </div>
</template>

<script>
import preloaderComponent from "./components/preloader.vue"
import headerComponent from "./components/header.vue"
import heroComponent from "./components/hero.vue"
import aboutComponent from "./components/about.vue"
import servicesComponent from "./components/services.vue"
import portfolioComponent from "./components/portfolio.vue"
import testimonialComponent from "./components/testimonials.vue"
import footerComponent from "./components/footer.vue"
import photoSwipComponent from "./components/photoswipeBackground.vue"

export default {
    components: {
        preloaderComponent,
        headerComponent,
        heroComponent,
        aboutComponent,
        servicesComponent,
        portfolioComponent,
        testimonialComponent,
        footerComponent,
        photoSwipComponent,
    },
}
</script>